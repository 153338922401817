import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { Exercise, Scheme } from "../types";

const EXERCISE_STORE_VERSION = 2;

export type ExerciseState = Exercise & {
  exerciseKey: string;
  completed: boolean;
  achievedEfforts: number[];
};

export type PBSource = "all-time" | "season";

type StoreState = {
  name: string;
  date: string;
  exercises: ExerciseState[];
  pbSource: PBSource;
  startDate?: number;
  endDate?: number;
  markExerciseCompleted: (exerciseKey: string, completed?: boolean) => void;
  updateExerciseAchievedEfforts: (
    exerciseKey: string,
    achievedEfforts: number[]
  ) => void;
  setPBSource: (pbSource: PBSource) => void;
  initialise: (scheme: Scheme) => void;
  toggleTimer: () => void;
  getDuration: () => number;
};

export const useExerciseStore = create<StoreState>()(
  persist(
    (set, get) => ({
      name: "",
      date: new Date().toLocaleDateString(),
      exercises: [],
      pbSource: "all-time",
      initialise: ({ name, date, exercises }) =>
        set({
          name,
          date,
          exercises: exercises.map(({ exercise_key, ...exercise }) => ({
            ...exercise,
            exerciseKey: exercise_key ?? getExerciseKey(exercise.exercise_name),
            completed: false,
            achievedEfforts: Array.from(
              { length: exercise.weights?.length || 0 },
              () => undefined
            ),
          })),
          startDate: undefined,
          endDate: undefined,
        }),

      markExerciseCompleted: (exerciseKey, completed = true) => {
        const updated = [...get().exercises].map((ex) => {
          if (ex.exerciseKey === exerciseKey) {
            return { ...ex, completed };
          } else {
            return ex;
          }
        });
        set({ exercises: updated });
      },

      updateExerciseAchievedEfforts: (exerciseKey, achievedEfforts) => {
        const updated = [...get().exercises].map((ex) => {
          if (ex.exerciseKey === exerciseKey) {
            return { ...ex, achievedEfforts };
          } else {
            return ex;
          }
        });
        set({ exercises: updated });
      },

      setPBSource: (pbSource) => {
        set({ pbSource });
      },

      toggleTimer: () => {
        if (!get().startDate) {
          set({ startDate: Date.now() });
        } else if (!get().endDate) {
          set({ endDate: Date.now() });
        } else {
          set({ startDate: undefined, endDate: undefined });
        }
      },

      getDuration: () => {
        const { startDate, endDate } = get();
        return (endDate ?? Date.now()) - startDate;
      },
    }),
    {
      name: `exercise-store`,
      version: EXERCISE_STORE_VERSION,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

function getExerciseKey(exerciseName: string) {
  return exerciseName.replace(/ /g, "-");
}
