import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";

export type WeightGoal = { percentage: number; reps: number };

export const WeightInput = ({
  weightGoal,
  exerciseKey,
  inputId,
  updateAchievedWeights,
  defaultValue,
  bestEffort,
}: {
  weightGoal: WeightGoal;
  exerciseKey: string;
  inputId: number;
  defaultValue?: number;
  updateAchievedWeights: (inputId: number, weight: number) => void;
  bestEffort?: number;
}) => {
  const inputIdKey = `${exerciseKey}-${inputId}`;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateAchievedWeights(
      inputId,
      parseFloat(event.target.value.replace(",", "."))
    );
  };

  const goalWeightInKilos = bestEffort
    ? Math.round(bestEffort * (weightGoal.percentage / 100) * 2) / 2
    : undefined;

  const label = `${`${weightGoal.reps} x ${weightGoal.percentage}%`} ${
    goalWeightInKilos ? ` = ${goalWeightInKilos} kg` : ""
  }`;

  return (
    <Box sx={{ m: 1 }}>
      <FormControl variant="outlined">
        <InputLabel
          htmlFor={inputIdKey}
          sx={{
            "+.MuiInputBase-root .MuiInputAdornment-root": { opacity: 0 },
            "&.Mui-focused": {
              "+.MuiInputBase-root .MuiInputAdornment-root": { opacity: 1 },
            },
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id={inputIdKey}
          endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          inputProps={{
            "aria-label": `${exerciseKey}-weight`,
          }}
          onChange={handleChange}
          defaultValue={defaultValue}
          label={label}
          autoComplete="off"
        />
      </FormControl>
    </Box>
  );
};
