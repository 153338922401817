import { Box } from "@mui/material";
import * as React from "react";
import { useShallow } from "zustand/react/shallow";
import { useExerciseStore } from "../state/exerciseStore";
import { DrawerHeader, DrawerWrapper } from "./drawer/DrawerWrapper";
import { MenuBar } from "./drawer/MenuBar";
import { ExerciseList } from "./ExerciseList";
import { SchemeHeader } from "./SchemeHeader";
import { Timer } from "./Timer";

type Props = {
  open: boolean;
  openDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

export const SchemeList = ({ openDrawer, open }: Props) => {
  const [name, exercises, started] = useExerciseStore(
    useShallow((state) => [state.name, state.exercises, !!state.startDate])
  );
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.default",
      }}
    >
      <MenuBar open={open} openDrawer={openDrawer}>
        <SchemeHeader
          name={
            started
              ? `✔️ ${exercises.filter((ex) => ex.completed).length}/${
                  exercises.length
                }`
              : name
          }
        />
        <Timer />
      </MenuBar>

      <DrawerWrapper open={open}>
        <DrawerHeader />

        <ExerciseList exercises={exercises} />
      </DrawerWrapper>
    </Box>
  );
};
