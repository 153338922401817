const MS_IN_HOUR = 3600000;
const MS_IN_MINUTE = 60000;
const MS_IN_SECOND = 1000;
const MS_IN_HUNDREDS = 10;

export function splitMsToTimeJS(ms: number | string) {
  ms = parseInt(`${ms}`);
  var hours = Math.floor(ms / MS_IN_HOUR);
  ms = ms % MS_IN_HOUR;
  var minutes = Math.floor(ms / MS_IN_MINUTE);
  ms = ms % MS_IN_MINUTE;
  var seconds = Math.floor(ms / MS_IN_SECOND);
  ms = ms % MS_IN_SECOND;
  var hundreds = Math.floor(ms / MS_IN_HUNDREDS);

  return [hours, minutes, seconds, hundreds];
}

export const prefixZero = (input: number | string) =>
  `${input}`.length < 2 ? `0${input}` : `${input}`;

export function formatDateTimeMySql(timestamp: number) {
  const tzOffset = new Date().getTimezoneOffset() * 60 * 1000; // offset in milliseconds
  return new Date(timestamp - tzOffset)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
}
