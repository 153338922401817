import { Typography } from "@mui/material";
import React from "react";
import { useExerciseStore } from "../state/exerciseStore";
import { UserBest } from "../types";

export const PersonalBestLabel = ({ bests }: { bests: UserBest }) => {
  const pbSource = useExerciseStore((state) => state.pbSource);
  const { unit, max_value, max_value_this_year } = bests;

  return (
    max_value && (
      <Typography sx={{ minWidth: 80, ml: 1, textAlign: "right" }}>
        {`🏆 ${
          pbSource === "all-time" ? max_value : max_value_this_year ?? max_value
        } ${unit}`}
      </Typography>
    )
  );
};
