import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const schemeTodoTheme = createTheme({
  palette: {
    background: {
      default: grey[200],
    },
  },
  typography: {
    h1: {
      fontSize: "1.8em",
    },
    h2: {
      fontSize: "1.5em",
    },
  },
});

schemeTodoTheme.typography.h1 = {
  ...schemeTodoTheme.typography.h1,
  [schemeTodoTheme.breakpoints.down("md")]: {
    fontSize: "1em",
  },
};

schemeTodoTheme.typography.h2 = {
  ...schemeTodoTheme.typography.h2,
  [schemeTodoTheme.breakpoints.down("md")]: {
    fontSize: "1em",
  },
};
