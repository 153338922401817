import { SwipeableDrawer } from "@mui/material";
import * as React from "react";
import { Menu } from "./Menu";

type Anchor = "top" | "left" | "bottom" | "right";

type Props = {
  anchor: Anchor;
  children: (options: {
    open: boolean;
    openDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
  }) => React.ReactNode;
};

export const SwipeableTemporaryDrawer = ({ children, anchor }: Props) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <React.Fragment>
      {children({
        open: state[anchor],
        openDrawer: toggleDrawer(anchor, true),
      })}
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <Menu toggleDrawer={(open) => toggleDrawer(anchor, open)} />
      </SwipeableDrawer>
    </React.Fragment>
  );
};
