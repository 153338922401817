import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type AuthState = {
  token?: string;
  name?: string;
  login: (token: string, name?: string) => void;
  logout: () => void;
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set, _get) => ({
      login: (token, name) => {
        set({ token, name });
      },
      logout: () => {
        set({ token: undefined, name: undefined });
      },
    }),
    {
      name: "auth-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
