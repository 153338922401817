import {
  Alert,
  AlertColor,
  Box,
  Button,
  FormControl,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { postSessionToAPI } from "../api/exercises";
import { useAuthStore } from "../state/authStore";
import { useExerciseStore } from "../state/exerciseStore";
import { useModalStore } from "../state/modalStore";
import { formatSessionForAPI } from "../util/formatSessionForAPI";
import { formatDateTimeMySql } from "../util/timeFunctions";

type Props = {};

type MUIAlert = {
  type: AlertColor;
  text: string;
};

export function SaveSessionModal({}: Props) {
  const [modalOpen, closeModal] = useModalStore(
    useShallow((state) => [
      state.saveSessionModalOpen,
      state.closeSaveSessionModal,
    ])
  );
  const token = useAuthStore((state) => state.token);
  const [name, date, exercises, getDuration, startDate, resetTimers] =
    useExerciseStore(
      useShallow((state) => [
        state.name,
        state.date,
        state.exercises,
        state.getDuration,
        state.startDate,
        state.toggleTimer,
      ])
    );
  const [intensity, setIntensity] = useState(1);
  const [saveName, setName] = useState(name);
  const [resultAlert, setAlert] = useState<MUIAlert>();

  useEffect(() => {
    setName(name);
  }, [name, setName]);

  const pressSave = async () => {
    if (!exercises.filter((ex) => ex.completed).length) {
      alert("Je hebt nog geen oefeningen voltooid");
      return;
    }
    if (!intensity || intensity < 1 || intensity > 10) {
      alert("Vul een intensiteit tussen 1 en 10 in.");
      return;
    }

    const saveResponse = await postSessionToAPI(
      token,
      formatSessionForAPI({
        sessionName: saveName,
        name,
        date,
        exercises,
        intensity,
        duration: Math.round(getDuration() / (60 * 1000)) * 60 * 1000,
        startDate: startDate ? formatDateTimeMySql(startDate) : date,
      })
    );
    if (saveResponse.status === "OK") {
      if (startDate) {
        resetTimers();
      }
      setAlert({ type: "success", text: "Training opgeslagen!" });
      setTimeout(resetModal, 1000);
    } else {
      setAlert({
        type: "error",
        text: saveResponse.message ?? "Er is iets misgegaan.",
      });
    }
  };

  const resetModal = () => {
    setAlert(undefined);
    closeModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={resetModal}
      aria-labelledby="save-session-modal-title"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        {resultAlert && (
          <Alert color={resultAlert.type}>{resultAlert.text}</Alert>
        )}
        <Typography id="login-modal-title" variant="h6" component="h2">
          Sessie opslaan
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl sx={{ my: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="save-session-modal-name">Naam</InputLabel>
            <OutlinedInput
              id="save-session-modal-name"
              type="text"
              value={saveName}
              label="Naam"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ my: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="save-session-modal-intensity">
              Intensiteit
            </InputLabel>
            <OutlinedInput
              id="save-session-modal-intensity"
              type="number"
              label="Intensiteit"
              value={intensity}
              placeholder="1-10"
              inputProps={{ min: 1, max: 10, step: 1 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIntensity(
                  event.target.value ? +event.target.value : undefined
                );
              }}
            />
          </FormControl>

          <Button variant="contained" onClick={pressSave}>
            Opslaan
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
