import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { postLogin } from "../api/auth";
import { useAuthStore } from "../state/authStore";
import { useModalStore } from "../state/modalStore";

type Props = {};

export function LoginModal({}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useAuthStore((state) => state.login);
  const [modalOpen, closeModal] = useModalStore(
    useShallow((state) => [state.loginModalOpen, state.closeLoginModal])
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const pressLogin = async () => {
    const loginResponse = await postLogin(email, password);
    if (loginResponse) {
      login(loginResponse.jwt, loginResponse.user_name);
      setEmail("");
      setPassword("");
      closeModal();
    } else {
      alert("Er is iets misgegaan. Probeer opnieuw in te loggen.");
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="login-modal-title"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="login-modal-title" variant="h6" component="h2">
          Log in op trainingslog
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl sx={{ my: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="login-modal-email">Email</InputLabel>
            <OutlinedInput
              id="login-modal-email"
              type="email"
              value={email}
              label="Email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ my: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="login-modal-password">Password</InputLabel>
            <OutlinedInput
              id="login-modal-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
            />
          </FormControl>

          <Button variant="contained" onClick={pressLogin}>
            Inloggen
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
