import { Card, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { useShallow } from "zustand/react/shallow";
import { ExerciseState, useExerciseStore } from "../state/exerciseStore";
import { BestEffortInput } from "./BestEffortInput";
import { PersonalBestLabel } from "./PersonalBestLabel";
import { WeightsSection } from "./WeightsSection";

export const ExerciseTodo = ({ exercise }: { exercise: ExerciseState }) => {
  const checked = exercise.completed;
  const [pbSource, markExerciseCompleted] = useExerciseStore(
    useShallow((state) => [state.pbSource, state.markExerciseCompleted])
  );

  const setChecked = (checked: boolean) =>
    markExerciseCompleted(exercise.exerciseKey, checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Card
      sx={{
        flex: 1,
        backgroundColor: checked ? green[100] : undefined,
      }}
    >
      <FormControlLabel
        sx={{
          m: 1,
          display: "flex",
          ".MuiFormControlLabel-label": {
            mx: 1,
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
          },
        }}
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <>
            <Typography>{exercise.full_description}</Typography>
            {exercise.user_best && (
              <PersonalBestLabel bests={exercise.user_best} />
            )}
          </>
        }
        labelPlacement="end"
      />

      {exercise.weights ? (
        <WeightsSection
          exerciseKey={exercise.exerciseKey}
          setChecked={setChecked}
          goalSets={exercise.weights.map((percentage, weightIndex) => ({
            percentage,
            reps: exercise.reps[weightIndex],
          }))}
          bestEffort={
            pbSource === "all-time"
              ? exercise.user_best?.max_value
              : exercise.user_best?.max_value_this_year ??
                exercise.user_best?.max_value
          }
        />
      ) : exercise.user_best ? (
        <BestEffortInput
          unit={exercise.user_best.unit}
          exerciseKey={exercise.exerciseKey}
          defaultValue={exercise.achievedEfforts?.[0]}
        />
      ) : null}
    </Card>
  );
};
