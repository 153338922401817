import { ExerciseState } from "../state/exerciseStore";

const getExercisesResults = (exercises: ExerciseState[]) =>
  exercises
    .filter((ex) => ex.achievedEfforts.filter((effort) => !!effort).length)
    .map((ex) => ({
      name: ex.exerciseKey,
      value: Math.max.apply(Math, ex.achievedEfforts.filter(effort => !!effort)),
      unit: ex.user_best?.unit,
    }));

const getSchemeText = (name: string, exercises: ExerciseState[]) => {
  return [
    name,
    ...exercises
      .filter(
        (ex) =>
          ex.completed || ex.achievedEfforts.filter((effort) => !!effort).length
      )
      .map(
        (ex) =>
          `- ${ex.full_description} ${
            !!ex.achievedEfforts.filter((e) => !!e).length
              ? `[${ex.achievedEfforts
                  .filter((e) => !!e)
                  .map((effort) => `${effort} ${ex.user_best?.unit}`)
                  .join(", ")}]`
              : ""
          }`
      ),
  ].join("\n");
};

export const formatSessionForAPI = ({
  sessionName,
  name,
  date,
  exercises,
  intensity = 1,
  duration: time = 60 * 60 * 1000,
  startDate,
}: {
  sessionName?: string;
  name: string;
  date: string;
  exercises: ExerciseState[];
  intensity?: number;
  duration?: number;
  startDate?: string;
}) => {
  return {
    name: sessionName || name,
    // description,
    date: startDate ?? date,
    type: "exercises",
    // location,
    intensity,
    intervals: [
      {
        type: "power",
        time,
        scheme: getSchemeText(name, exercises),
        exercises_results: getExercisesResults(exercises),
      },
    ],
  };
};
