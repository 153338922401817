import { ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { fetchSchemeFromAPI } from "../api/exercises";
import { useAuthStore } from "../state/authStore";
import { useExerciseStore } from "../state/exerciseStore";
import { schemeTodoTheme } from "../util/Theme";
import { SwipeableTemporaryDrawer } from "./drawer/SwipeableTemporaryDrawer";
import { LoginModal } from "./LoginModal";
import { SaveSessionModal } from "./SaveSessionModal";
import { SchemeList } from "./SchemeList";

export function Root() {
  const initializeScheme = useExerciseStore((state) => state.initialise);
  const [token, login] = useAuthStore(
    useShallow((state) => [state.token, state.login])
  );
  const queryParams = new URL(location.href).searchParams;

  // Login from URL
  useEffect(() => {
    const tokenInUrl = queryParams.has("token");
    if (tokenInUrl) {
      if (!token) {
        const tokenFromQueryParams = queryParams.get("token") || undefined;
        const nameFromQueryParams = queryParams.get("name") || undefined;
        login(tokenFromQueryParams, nameFromQueryParams);
      }
      location.href = "/";
    }
  }, [queryParams]);

  async function setDataFromAPI() {
    const id = new URL(window.location.href).searchParams.get("id");
    const apiData = await fetchSchemeFromAPI(token, id ? +id : undefined);

    if (apiData) {
      const date = useExerciseStore.getState().date;
      const anyExerciseMarkedCompleted = useExerciseStore
        .getState()
        .exercises.some((exercise) => exercise.completed);

      if (!date || apiData.date > date || !anyExerciseMarkedCompleted) {
        initializeScheme(apiData);
      }
    }
  }

  useEffect(() => {
    setDataFromAPI();
  }, [token]);

  return (
    <ThemeProvider theme={schemeTodoTheme}>
      <SwipeableTemporaryDrawer anchor="left">
        {({ openDrawer, open: drawerOpen }) => (
          <SchemeList openDrawer={openDrawer} open={drawerOpen} />
        )}
      </SwipeableTemporaryDrawer>
      <LoginModal />
      <SaveSessionModal />
    </ThemeProvider>
  );
}
