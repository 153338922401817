import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useExerciseStore } from "../state/exerciseStore";
import { WeightGoal, WeightInput } from "./WeightInput";

export const WeightsSection = ({
  goalSets,
  setChecked,
  exerciseKey,
  bestEffort,
}: {
  goalSets: WeightGoal[];
  setChecked: (checked: boolean) => void;
  exerciseKey: string;
  bestEffort?: number;
}) => {
  const [achievedEfforts, updateAchievedWeights] = useExerciseStore(
    useShallow((state) => [
      state.exercises.find((ex) => ex.exerciseKey === exerciseKey).achievedEfforts,
      state.updateExerciseAchievedEfforts,
    ])
  );

  useEffect(() => {
    if (achievedEfforts.every((effort) => effort > 0)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [achievedEfforts]);

  const updateAchievedWeightsCallback = useCallback(
    (inputId: number, weight: number) => {
      const copyWeights = [...achievedEfforts];
      copyWeights[inputId] = weight;
      updateAchievedWeights(exerciseKey, copyWeights);
    },
    [achievedEfforts, updateAchievedWeights]
  );

  return (
    <Box sx={{ m: 1.5 }}>
      {goalSets.map((weightGoal, weightIndex) => (
        <WeightInput
          key={weightIndex}
          exerciseKey={exerciseKey}
          inputId={weightIndex}
          weightGoal={weightGoal}
          updateAchievedWeights={updateAchievedWeightsCallback}
          defaultValue={achievedEfforts[weightIndex]}
          bestEffort={bestEffort}
        />
      ))}
    </Box>
  );
};
