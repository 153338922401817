import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type ModalState = {
  loginModalOpen: boolean;
  openLoginModal: () => void;
  closeLoginModal: () => void;
  saveSessionModalOpen: boolean;
  openSaveSessionModal: () => void;
  closeSaveSessionModal: () => void;
};

export const useModalStore = create<ModalState>()(
  persist(
    (set, _get) => ({
      loginModalOpen: false,
      openLoginModal: () => {
        set({ loginModalOpen: true });
      },
      closeLoginModal: () => {
        set({ loginModalOpen: false });
      },
      saveSessionModalOpen: false,
      openSaveSessionModal: () => {
        set({ saveSessionModalOpen: true });
      },
      closeSaveSessionModal: () => {
        set({ saveSessionModalOpen: false });
      },
    }),
    {
      name: "modal-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
