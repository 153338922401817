import { List, ListItem } from "@mui/material";
import { ExerciseState } from "../state/exerciseStore";
import { ExerciseTodo } from "./ExerciseTodo";

export const ExerciseList = ({ exercises }: { exercises: ExerciseState[] }) => {
  return (
    <List>
      {exercises.map((exercise) => (
        <ListItem key={exercise.exerciseKey}>
          <ExerciseTodo exercise={exercise} />
        </ListItem>
      ))}
    </List>
  );
};
