import { API_BASE_URL } from "./base";

export async function postLogin(email: string, password: string) {
  const response = await fetch(`${API_BASE_URL}/security/login_jwt`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
  });

  if (response) {
    return await response.json();
  } else {
    return undefined;
  }
}
